import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Anchor } from '@twilio-paste/anchor';
import { AspectRatio } from '@twilio-paste/aspect-ratio';
import { Box } from '@twilio-paste/box';
import Changelog from '@twilio-paste/aspect-ratio/CHANGELOG.md';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteLayout(filter: {name: {eq: "@twilio-paste/aspect-ratio"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "images/anchor-dont-1@4x.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(fields: {slug: {eq: "/layout/aspect-ratio/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Aspect Ratio" categoryRoute={SidebarCategoryRoutes.LAYOUT} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/layout/aspect-ratio" storybookUrl="/?path=/story/layout-aspect-ratio--default" data={props.data.allPasteLayout.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Aspect Ratio`}</h3>
        <p>{`The Aspect Ratio layout component dynamically modifies the height of an element based on its
current width to match a width-to-height ratio. For example, a 4:3 ratio would make
an element with a width of 400px have a height of 300px. This updates in real time
when you resize the screen.`}</p>
        <h2>{`Composition Notes`}</h2>
        <p>{`Aspect Ratio must contain exactly one child element. The child element should be sized
fully to the Aspect Ratio bounding box so that it resizes correctly. One way of achieving
this is using an absolutely positioned `}<a parentName="p" {...{
            "href": "/primitives/box"
          }}>{`Box primitive`}</a>{`.`}</p>
        <p>{`Be considerate of smaller devices when using this component. If necessary, you can
modify the aspect ratio property at certain media query breakpoints.`}</p>
        <h3>{`When to use Aspect Ratio`}</h3>
        <p>{`Aspect Ratio can be used to embed images and videos.`}</p>
        <p>{`Use Aspect Ratio to make sure images and videos are scaled correctly on
displays of various sizes. For example, you can use Aspect Ratio when a
customer needs to see a preview of an uploaded document.`}</p>
        <LivePreview scope={{
          AspectRatio,
          Box
        }} language="jsx" mdxType="LivePreview">
  {`<Box
  padding="space30"
  maxWidth="size60"
  borderColor="colorBorderLight"
  borderStyle="solid"
  borderWidth="borderWidth10"
>
  <AspectRatio ratio="16:9">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/OCUlE5ldPvM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </AspectRatio>
</Box>`}
        </LivePreview>
        <p>{`Aspect Ratio can also be used to embed components, or other HTML elements that
needs to be set to a specific width-to-height ratio. A component or other HTML
element use case is the need to align an HTML element with an image in two columns.`}</p>
        <LivePreview scope={{
          Anchor,
          AspectRatio,
          Box
        }} language="jsx" mdxType="LivePreview">
  {`<Box
  padding="space30"
  maxWidth="size50"
  borderColor="colorBorderLight"
  borderStyle="solid"
  borderWidth="borderWidth10"
>
  <AspectRatio ratio="4:3">
    <Box position="absolute" top={0} right={0} bottom={0} left={0} display="flex" alignItems="center" justifyContent="center">
      <Anchor href="https://org2.salsalabs.com/o/6857/p/salsa/donation/common/public/?donate_page_KEY=15780">Donate to the NAACP Legal Defense Fund</Anchor>
    </Box>
  </AspectRatio>
</Box>`}
        </LivePreview>
        <p>{`Another use case is for color swatches on a documentation site. These color swatches
could be a specific height/width div that need to resize on specific devices.
Aspect Ratio will allow the color swatches to resize correctly based on a ratio.`}</p>
        <LivePreview scope={{
          AspectRatio,
          Box
        }} language="jsx" mdxType="LivePreview">
  {`<Box
  padding="space30"
  maxWidth="size50"
  borderColor="colorBorderLight"
  borderStyle="solid"
  borderWidth="borderWidth10"
>
  <AspectRatio ratio="4:3">
    <Box position="absolute" top={0} right={0} bottom={0} left={0} backgroundColor="colorBackgroundBrand" />
  </AspectRatio>
</Box>`}
        </LivePreview>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use for an image or embedded video, and have it resize at a specific ratio." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t use with fixed size (width & height) elements, as those will not adhere to the width-to-height ratio." preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use to embed a component or other HTML element." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t use without any child elements, because this is only a container element." preview={false} mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/aspect-ratio - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import {AspectRatio} from '@twilio-paste/core/aspect-ratio';
import {Box} from '@twilio-paste/core/box';

const Component = () => (
  <AspectRatio ratio="4:3">
    <Box position="absolute" top={0} right={0} bottom={0} right={0}>
      Test
    </Box>
  </AspectRatio>
);
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ratio`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines the aspect ratio of the element. Use a colon separated number pattern (width:height). Required`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`4:3`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      